import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/swipe/:token/:tg?',
        name: 'swipe',
        component: () => import('../views/SwipeView.vue')
    },
    {
        path: '/chat/:token/:tg?',
        name: 'chat',
        component: () => import('../views/ChatView.vue')
    },
    {
        path: '/chats/:token/:tg?',
        name: 'chats-list',
        component: () => import('../views/./ChatsListView.vue')
    },
    {
        path: '/session/:token/:tg?',
        name: 'session',
        component: () => import('../views/./SessionView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
