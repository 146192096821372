<template>
  <nav class="du-navbar bg-primary text-white" dir="rtl">
    <div class="flex-1">
      <h1 class="text-2xl">{{ $getText('navbar_title') }}</h1>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'NavBar'
}
</script>