import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import {getText} from "@/utils";


const utilsPlugin = {
    install(app) {
        app.config.globalProperties.$getText = getText;
    }
}

createApp(App).use(router).use(utilsPlugin).mount('#app');
