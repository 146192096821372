export const texts = {
    // global
    ok: 'حله',
    error_title: 'خطا',
    dc_error: 'اتصال اینترنتت مشکل داره. صفحرو رفرش کن',
    media_error: 'رسانه به مشکل خورده. صفحرو رفرش کن',
    disconnected: 'از سرور قطع شدی. صفحرو رفرش کن',
    reconnect: 'اتصال مجدد',
    // Navbar
    navbar_title: 'پیداش کن ‼️',
    // HomeView
    go_to_robot: 'ورود به ربات',
    // LoadingWidget
    loading_default_text: 'درحال بارگذاری',
    // ChatsListView
    cl_loading_text: 'درحال بارگذاری گفتگو ها',
    cl_no_chat: 'هنوز با کسی گپ نزدی',
    cl_request_error: 'خطا',
    // ChatView
    show_profile: 'نمایش پروفایل',
    loading_messages: 'درحال بارگذاری پیام ها',
    cv_input_placeholder: 'ارسال پیام',
    // SwipeView
    show_filters: 'نمایش فیلتر ها',
    buy_coin: 'خرید سکه',
    connected_to_user: 'هورا! وصل شدید',
    searching: 'الان یکیو برات پیدا میکنم',
    like: 'لایک',
    connect: 'وصلم کن',
    next: 'بعدی',
    // SessionView
    waiting: 'در انتظار کاربر مقابل',
    peer_left_title: 'اتمام تماس',
    peer_left_text: 'کاربر مقابل تماس رو ترک کرد',
    // MediaPermission
    camera_permission_needed: 'برای ادامه دسترسی دوربین لازمه',
    allow_camera: 'دسترسی دوربین',
    // LoginSection
    could_not_login: "Couldn't login",
    error: 'Error Description',
}

export const expandWindow = function () {
    if (window.Telegram !== undefined)
        window.Telegram.WebApp.expand();
}

export const closeWindow = function () {
    if (window.Telegram !== undefined)
        window.Telegram.WebApp.close();
}

export const unixTime = function () {
    return Math.round(+new Date() / 1000);
}

export const getUserMedia = function (okCallback, errCallback) {
    navigator.mediaDevices.getUserMedia({video: true, audio: true})
        .then(stream => okCallback(stream))
        .catch(err => errCallback(err));
}

export const getText = function (slug) {
    return texts[slug];
}

export const closeWebsocket = function (webSocket) {
    if (webSocket !== null && webSocket.readyState !== WebSocket.CLOSED)
        webSocket.close();
}

export const reloadWindow = function () {
    window.location.reload();
    return false;
}